export const themeList = [
  {
    id: 1,
    name: "default Theme",
    elements: {
      type: "light",
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      colors: {
        primary: "#43C2F2",
        primaryPlus: "#1c232b",
        secondary: "#ecf0f8",
        bgGreen: "#41E2A8",
        bgBlue: "#2C9AD4",
        bgLightBlue: "#E3F5F9",
        bgDark: "#012241",
        bgLight: " #F5F6FA",
        borderPrimary: "#B8CBE9",
        borderLight: "#eee",
        borderGrey: "#ddd",
        textBasic: "#012241",
        textDark: "#1F2945",
        textBlack: "#464255",
        textWhite: "#fff",
        textGrey: "#c7c7c7",
        textDarkGrey: "#696969",
        captionText: "#808080",
        disabled: "#e0e0e0",
        delete: "#ED6262",
        success: "#19BE96",
        warning: "#FAAF19",
        danger: "#FC2E53",
      },
    },
  },
];
