/** THEME ACTIONS */
export const DARK_THEME = "DARK_THEME";
export const CHANGE_THEME = "CHANGE_THEME";
export const GET_THEME_LIST = "GET_THEME_LIST";
export const DEFAULT_THEME = "DEFAULT_THEME";
export const INCREASE_FONT = "INCREASE_FONT";
export const DECREASE_FONT = "DECREASE_FONT";
export const RESET_FONT = "RESET_FONT";

/** SETTINGS ACTIONS */
export const SET_LANG = "SET_LANG";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const CLEAR_AUTH_USER = "CLEAR_AUTH_USER";
export const OPEN_SIDE_MENU = "OPEN_SIDE_MENU";
export const CLOSE_SIDE_MENU = "CLOSE_SIDE_MENU";
export const SET_HEADER_PAGE_TITLE = "SET_HEADER_PAGE_TITLE";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const CLEAR_ALERT_MESSAGE = "CLEAR_ALERT_MESSAGE";
export const OPEN_VIDEO_FRAME = "OPEN_VIDEO_FRAME";
export const OPEN_PDF_VIEWER = "OPEN_PDF_VIEWER";
export const SET_SUB_HEADER = "SET_SUB_HEADER";
export const SET_DIRECT_POPUP_DATA = "SET_DIRECT_POPUP_DATA";
export const SET_INVESIGATION_PARTY_ID = "SET_INVESIGATION_PARTY_ID";
export const SET_PHRASE_LOADER = "SET_PHRASE_LOADER";
export const CLEAR_PHRASE_LOADER = "CLEAR_PHRASE_LOADER";
